/* This is the vendor import that contains items needed for most of the "main" files to build out correctly
it can be loaded multiple times because most of these files should not bulk up the final built file.
*/
/* Can't use this because we're using root-defined colors

@function set-secondary-font-color($color) {
	@if ($color == #fff) {
		@return #a2a2a2;
	} @else if (lightness($color) > 50) {
		@return darken($color, 28%)
	} @else {
		@return lighten($color, 20%);
	}
}
 */
.retrieve-password-con {
  margin: 0 auto;
}
.retrieve-password-con .password-wrapper {
  width: 100%;
  max-width: 370px;
  background: #fff;
  margin: 5rem auto;
  box-shadow: 0 0 10px rgba(213, 213, 213, 0.75);
}
.retrieve-password-con .html {
  font-size: 0.9rem;
  padding: 0;
}
.retrieve-password-con .html .title {
  background-color: rgb(var(--color-brand-main));
  text-align: center;
  color: rgb(var(--color-brand-main-reverse));
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: center;
  padding: 0.75rem 0.5rem;
}
.retrieve-password-con .html p {
  padding: 1rem 2rem 0;
}
.retrieve-password-con span.email {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
.retrieve-password-con input[type=text] {
  padding: 7px 8px 7px;
  font-size: 1.125rem;
  line-height: 1;
  color: #7B7B7B;
  width: 100%;
  border: 1px solid #83898C;
}
.retrieve-password-con form#retrieve-password {
  text-align: center;
}
.retrieve-password-con form#retrieve-password div.email {
  text-align: left;
}
.retrieve-password-con .retrieve-password {
  padding: 0 30px 30px;
}
.retrieve-password-con .retrieve-password .btn {
  --bs-btn-color: rgb(var(--color-brand-main-reverse));
  --bs-btn-bg: rgb(var(--color-brand-main));
  --bs-btn-border-color: rgb(var(--color-brand-main));
  --bs-btn-hover-color: rgb(var(--color-brand-main-reverse));
  --bs-btn-hover-bg: rgb(var(--color-brand-main-dark));
  --bs-btn-hover-border-color: rgb(var(--color-brand-main-dark));
  --bs-btn-focus-shadow-rgb: rgba(var(--color-brand-main), .5);
  --bs-btn-active-bg: rgb(var(--color-brand-main-dark));
  --bs-btn-active-border-color: rgb(var(--color-brand-main-dark));
  --bs-btn-disabled-color: rgb(var(--color-brand-main-reverse));
  --bs-btn-disabled-bg: rgba(var(--color-brand-main), .5);
  --bs-btn-disabled-border-color: rgba(var(--color-brand-main), .5);
  margin: 0.5rem auto 0;
  width: 100%;
}

.open > .retrieve-password-con .retrieve-password button.dropdown-toggle {
  color: rgb(var(--color-brand-main-reverse));
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.open > .retrieve-password-con .retrieve-password button.dropdown-toggle:hover, .open > .retrieve-password-con .retrieve-password button.dropdown-toggle:focus, .open > .retrieve-password-con .retrieve-password button.dropdown-toggle .focus {
  color: rgb(var(--color-brand-main-reverse));
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main-dark));
}
.open > .retrieve-password-con .retrieve-password button.dropdown-toggle .disabled:hover {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main-dark));
}
.open > .retrieve-password-con .retrieve-password button.dropdown-toggle:disabled:hover {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main-dark));
}

.password-reset {
  background: #fff;
  font-family: "open-sans", sans-serif;
  border-radius: 0.3rem;
  margin-top: 5rem;
}
.password-reset .title {
  background-color: #A8ABBA;
  text-align: center;
  color: #fff;
  font-size: 1.6em;
  vertical-align: center;
  text-transform: uppercase;
  padding: 0.5rem 0;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  font-family: "raleway", sans-serif;
  font-weight: 700;
}
.password-reset p {
  padding: 1rem 2rem 0;
}
.password-reset .label {
  font-size: 0.85rem;
  color: #343940;
  text-transform: uppercase;
  font-family: "open-sans", sans-serif;
}
.password-reset .label label {
  margin-bottom: 0.25rem;
}
.password-reset .actions {
  padding: 0 3rem 2rem;
}
.password-reset .actions .btn {
  margin: 0.5rem auto 0;
  display: block;
}

.retrieve-password-con-wrapper {
  display: flex;
  width: 100%;
  background-image: var(--login-bg-img, url("/_design/engages-standard/images/login-bg.jpg"));
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(var(--color-brand-main), 0.8);
  background-size: cover;
  background-blend-mode: multiply;
}
@media (max-width: 1023px) {
  .retrieve-password-con-wrapper {
    height: 100%;
  }
}

.engages-body-con > .box-wc {
  background: #F4F6FC;
  width: 100%;
}
@media (min-width: 1024px) {
  .engages-body-con > .box-wc {
    display: table;
  }
}

header {
  display: none;
}

main {
  height: 100%;
}
@media (min-width: 1024px) {
  main {
    display: table-row;
  }
}
main > .box-wc {
  height: 100%;
  max-width: 100vw !important;
}
@media (min-width: 1024px) {
  main > .box-wc {
    display: flex;
  }
}
main .buyer-login {
  padding: 0 20px;
  width: 100%;
}
@media (min-width: 1024px) {
  main .buyer-login {
    padding: 60px;
    min-height: 650px;
    position: relative;
  }
}
main .buyer-login {
  text-align: left;
  background-image: var(--login-bg-img, url("/_design/engages-standard/images/login-bg.jpg"));
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(var(--color-brand-main), 0.8);
  background-size: cover;
  background-blend-mode: multiply;
}
@media (max-width: 1023px) {
  main .buyer-login {
    height: 100%;
  }
}
main .buyer-login .text {
  margin: 0;
  padding: 0;
}
main .buyer-login .logo-container {
  text-align: center;
}
main .buyer-login .logo-container .primary-logo {
  margin-bottom: 1.5rem;
}
main .buyer-login h1 {
  display: inline-block;
  color: rgb(var(--color-brand-accent));
  max-width: 450px;
  margin: 30px auto;
  line-height: 1.2;
  text-align: center;
  font-size: 2rem;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}
@media (min-width: 1024px) {
  main .buyer-login h1 {
    position: relative;
    margin: 0 10px 0 0;
    left: 10px;
    text-align: left;
    background: rgba(var(--color-brand-accent), .85);
    color: #fff;
    display: inline;
    line-height: 1.6;
    padding: 0;
    font-size: 3.75rem;
    text-transform: uppercase;
    box-shadow: 10px 0 0 rgba(var(--color-brand-accent), .85), -10px 0 0 rgba(var(--color-brand-accent), .85);
  }
}
main .buyer-login h1 .message {
  text-transform: none;
  font-size: 1.5rem;
  display: none;
}
@media (min-width: 1024px) {
  main .buyer-login h1 .message {
    display: inline;
    font-size: 3rem;
  }
}
main .login-con, main .login-password-con {
  background: #fff;
  width: 100%;
  max-width: 450px;
  padding: 30px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.18);
  margin: 10px auto 0;
  color: #55595b;
}
main .login-con .account-link form:after, main .login-con .set-password form:after, main .login-password-con .account-link form:after, main .login-password-con .set-password form:after {
  content: none;
}
main .login-con form, main .login-password-con form {
  position: relative;
}
main .login-con form:after, main .login-password-con form:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  top: 100%;
  left: 0;
  margin: 1.25rem 0;
}
main .login-con .retrieve-password, main .login-password-con .retrieve-password {
  margin-top: 2rem;
}
main .login-con .error, main .login-password-con .error {
  display: block;
  position: relative;
  padding: 0.6rem 0.6rem 0.6rem 2rem;
  color: #343940;
  border: 1px solid #f8eaea;
  border-top: 2px solid #B52625;
  background-color: #fdf9f9;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-size: 0.925rem;
  line-height: 1.2;
}
main .login-con .error:before, main .login-password-con .error:before {
  content: "\e417";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  color: #B52625;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 2rem;
  margin: 0;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
main .login-con .error a, main .login-password-con .error a {
  color: #B52625;
  margin-left: 2px;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
}
main .login-con .error a:hover, main .login-password-con .error a:hover {
  text-decoration: none;
}
main .login-con .success, main .login-password-con .success {
  display: block;
  position: relative;
  padding: 0.6rem 0.6rem 0.6rem 2rem;
  color: #343940;
  border: 1px solid #e2f0f0;
  border-top: 2px solid #078345;
  background-color: #f7fbf9;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-size: 0.925rem;
  line-height: 1.2;
}
main .login-con .success:before, main .login-password-con .success:before {
  content: "\f058";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  color: #078345;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 2rem;
  margin: 0;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
main .login-con .success a, main .login-password-con .success a {
  color: #078345;
  margin-left: 2px;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
}
main .login-con .success a:hover, main .login-password-con .success a:hover {
  text-decoration: none;
}
main .login-con .info, main .login-password-con .info {
  display: block;
  position: relative;
  padding: 0.6rem 0.6rem 0.6rem 2rem;
  color: #343940;
  border: 1px solid #e3f1ea;
  border-top: 2px solid #007F7F;
  background-color: #f5fbfb;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-size: 0.925rem;
  line-height: 1.2;
}
main .login-con .info:before, main .login-password-con .info:before {
  content: "\f05a";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  color: #007F7F;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 2rem;
  margin: 0;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
main .login-con .info a, main .login-password-con .info a {
  color: #007F7F;
  margin-left: 2px;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
}
main .login-con .info a:hover, main .login-password-con .info a:hover {
  text-decoration: none;
}
main .login-con .message, main .login-password-con .message {
  font-size: 1rem;
}
main .login-con .message:before, main .login-password-con .message:before {
  top: 12px;
}
main .login-con .password-reset, main .login-password-con .password-reset {
  margin-top: 0;
}
main .login-con .password-reset .title, main .login-password-con .password-reset .title {
  background-color: rgb(var(--color-brand-main));
  font-size: 1.4rem;
  padding: 0.3rem 0;
  text-transform: capitalize;
  margin-top: 2rem;
}
main .login-con .password-reset p, main .login-password-con .password-reset p {
  padding: 0;
}
main .login-con .title, main .login-password-con .title {
  margin: 0;
  padding: 0;
}
main .login-con .title h2, main .login-password-con .title h2 {
  font-size: 1.25rem;
  margin: 0 0 0.25em;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1px;
  text-align: left;
  color: rgb(var(--color-brand-main));
  text-transform: uppercase;
}
main .login-con .title p, main .login-password-con .title p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 15px;
}
main .login-con label, main .login-password-con label {
  display: block;
  font-size: 1rem;
}
main .login-con input, main .login-password-con input {
  font-size: 1rem;
  padding: 8px 10px;
  border: 1px solid #cfcfcf;
  width: 100%;
}
main .login-con .actions, main .login-password-con .actions {
  text-align: right;
}
main .login-con .btn, main .login-password-con .btn {
  display: block;
  background: rgb(var(--color-brand-main));
  -webkit-appearance: none;
  color: rgb(var(--color-brand-main-reverse));
  border: none;
  border-radius: 2rem;
  letter-spacing: 2px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  margin: 15px 0 0 auto;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
}
main .login-con .message.important, main .login-password-con .message.important {
  display: block;
  position: relative;
  padding: 0.6rem 0.6rem 0.6rem 2rem;
  color: #343940;
  border: 1px solid #f8eaea;
  border-top: 2px solid #B52625;
  background-color: #fdf9f9;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-size: 0.925rem;
  line-height: 1.2;
  margin-bottom: 10px;
}
main .login-con .message.important:before, main .login-password-con .message.important:before {
  content: "\e417";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  color: #B52625;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 2rem;
  margin: 0;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
main .login-con .message.important a, main .login-password-con .message.important a {
  color: #B52625;
  margin-left: 2px;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
}
main .login-con .message.important a:hover, main .login-password-con .message.important a:hover {
  text-decoration: none;
}